@import 'src/assets/scss/variable.scss';

.outlined-default {
  border: 1px solid $color13;
  color: $color13;
  border-radius: 5px;
  font-size: 12px;
  font-family: 'Poppins-SemiBold';
  font-weight: 600;
  &:hover {
    color: $color13;
    border: 1px solid $color13;
  }
}

.disable-frac-select {
  opacity: 0.5 !important;
}
