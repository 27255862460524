@import 'src/assets/scss/responsive.scss';
@import 'src/assets/scss/variable.scss';

.modal-notify-title {
  font-weight: 700;
  font-size: 24px;
  color: $color-pure-black;
  margin-bottom: 24px;
}

.modal-notify-detail {
  max-height: 400px;
  overflow-y: scroll;
  color: $color-pure-black;
  &__title {
    font-size: 20px;
    font-weight: 700;
  }
  &__date {
    font-weight: 400;
    font-size: 14px;
    color: $color-grey-2;
    margin: 16px 0px;
  }
  &__message {
    color: $color-grey-2;
    font-size: 16px;
    font-weight: 400;

    .event_name_link {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      color: $color-green-7;
      cursor: pointer;
    }
  }
}

.modal-notify-wrapper-Dark {
  .ant-modal-content {
    background: $dark-1;
    border: none;
  }

  .modal-notify.Dark {
    .modal-notify-title {
      color: $text-color-white;
    }
  }

  .modal-notify-detail {
    color: $text-color-white;

    &__date {
      color: $dark-mode-grey-2;
    }

    &__message {
      color: $dark-mode-grey-2;
    }
  }
}
