@import 'src/assets/scss/variable.scss';

.notification-container-mobile {
  background: $color-pure-white;
  height: 100vh;

  .line {
    height: 1px;
    background-color: $color-grey-5;
  }

  .secondary-line {
    height: 1px;
    background-color: $color-grey-9;
    margin: 8px 16px;
  }

  .notification__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 17px;
    padding-bottom: 12px;

    .count {
      font-family: 'Poppins-Bold';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: $color12;
    }

    .mark-all {
      font-family: 'Poppins-Regular';
      font-size: 14px;
      line-height: 21px;
      color: $color-grey-2;
      cursor: pointer;
    }
  }

  .notification__content {
    padding: 8px;

    .notification__item {
      //   width: 100%;
      display: flex;
      align-items: center;
      padding: 8px 10px;
      margin: 0 9px;
      cursor: pointer;

      &:hover {
        background: $color-grey-9;
        border-radius: 4px;
      }

      .dot-icon {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 8px;
      }

      .inactive {
        background: $color-grey-5;
        visibility: hidden;
      }

      .active {
        background: $color13;
      }

      .notification-icon {
        color: $color12;
        margin-right: 16px;
      }

      .notification-item__content {
        font-family: 'Poppins-Regular';
        font-style: normal;
        line-height: 150%;
        display: flex;
        flex-direction: column;

        .title {
          font-size: 14px;
          color: $color-pure-black;
        }

        .content {
          font-size: 12px;
          color: $color-grey-2;

          .event_name_link {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            color: $color-green-7;
            cursor: pointer;
          }
        }

        .time {
          font-size: 12px;
          color: $color-grey-2;
        }
      }
    }
  }

  .view-all-notification {
    color: $color13;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    margin-bottom: 24px;
    cursor: pointer;
    a {
      color: $color13;
    }
    &:hover {
      opacity: 0.8;
    }
  }
}

.notification-container-mobile.Dark {
  background: $dark-1;

  .line {
    background-color: $dark-mode-grey-1;
  }

  .secondary-line {
    background-color: $dark-2;
  }

  .notification__header {
    .count {
      color: $text-color-white;
    }

    .mark-all {
      color: $dark-mode-grey-2;
    }
  }

  .notification__content {
    .notification__item {
      &:hover {
        background: $dark-2;
      }

      .inactive {
        background: $color-grey-5;
      }

      .active {
        background: $color13;
      }

      .notification-icon {
        color: $text-color-white;
      }

      .notification-item__content {
        .title {
          color: $text-color-white;
        }

        .content {
          color: $dark-mode-grey-2;
        }

        .time {
          color: $dark-mode-grey-2;
        }
      }
    }
  }
}
