@import 'src/assets/scss/variable.scss';
@import 'src/assets/scss/responsive.scss';

.type-connect-group {
  display: flex;
  justify-content: space-between;
  @include mobileFrac {
    flex-direction: column;
  }
  width: 100%;
  background: transparent;

  .box-connect {
    &:hover {
      background: $color4;
    }
    cursor: pointer;
    width: 45.5%;
    @include mobileFrac {
      width: 100%;
      margin-top: 20px;
    }
    height: 148px;
    border: 1px solid $color-grey-5;
    border-radius: 8px;
    background: $color-pure-white;
    .group-content {
      flex-direction: column;
      text-align: center;
      margin-top: 24px;
      .text {
        font-size: 14px;
        color: $color-pure-black;
        font-family: 'Poppins-SemiBold';
        font-weight: 600;
        margin-top: 15px;
        line-height: 21px;
      }
    }
  }
  .active-connect {
    background: $color4;
  }
}
.footer-connect-modal {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-family: 'Poppins-Regular';
  color: $color13;
  line-height: 21px;
  margin-top: 20px;

  div {
    cursor: pointer;
    &:hover {
      color: $color-green-6;
    }
  }
}

.error-message-metamask {
  font-size: 14px;
  line-height: 21px;
  color: $color11;
  font-family: 'Poppins-SemiBold';
  font-weight: 600;
  margin-bottom: 12px;
}

.connect-wallet-modal-Dark {
  .ant-modal-content {
    background: $dark-1;
    border-color: $dark-mode-grey-1;

    .ant-modal-header {
      background: transparent;

      .ant-modal-title {
        color: $text-color-white;
      }
    }

    .footer-connect-modal {
      color: $text-color-white;
    }

    .type-connect-group {
      .box-connect {
        border: 1px solid $dark-mode-grey-1;
        background: transparent;

        &:hover {
          background: $dark-mode-green-1;
          border: 1px solid $dark-mode-green-2;
        }

        .group-content {
          .text {
            color: $text-color-white;
          }
        }
      }

      .active-connect {
        background: $dark-mode-green-1;
        border: 1px solid $dark-mode-green-2;
      }
    }
  }
}
