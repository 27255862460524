@import 'src/assets/scss/variable.scss';
@import 'src/assets/scss/responsive.scss';

.frac-select {
  display: flex;
  align-items: center;

  @include mobileFrac {
    min-width: 106px;
    width: 100%;
  }
  min-width: 150px;
  font-size: 0.875rem;

  .ant-select-selector {
    min-height: 44px;
    border: 1px solid $color-grey-5 !important;
    border-radius: 8px !important;
    padding: 12px 16px;
    display: flex;
    align-items: center;

    font-family: 'Poppins-Regular';
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
  }

  .ant-select-arrow {
    color: $color12;
  }

  .label-head {
    color: $color-grey-2;
  }

  .ant-select-selection-search {
    display: flex;
    align-items: center;
    font-family: 'Poppins-Regular';
    font-weight: 500;
  }
}

.frac-select-Dark,
.frac-select-with-label-Dark {
  .ant-select-selector {
    background: transparent !important;
    color: $dark-mode-grey-4 !important;
    border: 1px solid $dark-mode-grey-1 !important;

    .ant-select-selection-item {
      color: $dark-mode-grey-4 !important;
    }
  }

  .ant-select-arrow {
    color: $dark-mode-grey-4;
  }
}

.frac-select-Dark.ant-select-focused {
  .ant-select-selector {
    border: 1px solid $dark-mode-grey-1 !important;
  }
}

.frac-select-with-label-Dark.ant-select-focused {
  .ant-select-selector {
    border: 1px solid $dark-mode-grey-1 !important;
  }
}

.ant-select-dropdown {
  border: 1px solid $color-grey-5;
  border-radius: 8px;
  background: $color-pure-white;
  color: $color12 !important;
  padding: 4px;

  .ant-select-item-option-active {
    background: $color-grey-3;
    border-radius: 4px;
  }

  .ant-select-item-option-selected {
    background: $color-grey-3;
    border-radius: 4px;
  }
}

.ant-select-item-option {
  height: 44px;
  line-height: 30px;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  font-family: 'Poppins-Regular';
  font-weight: 500;
  height: fit-content;
  color: $color12;
  // line-height: 36px;
  // display: flex;
  align-items: center;
  padding-left: 4px;
}

.select-custom {
  position: relative;

  .frac-select {
    min-width: 140px !important;
    width: 180px !important;

    .ant-select-selector {
      min-height: 44px;
      border-radius: 8px !important;
      padding: 0 10px;
      border: 1px solid $color-grey-5 !important;
      padding-left: 60px !important;
    }
  }
  label {
    position: absolute;
    padding-right: -100px;
    top: 10px;
    left: 10px;
  }
}

.disable-frac-select {
  // opacity: 0.5;
}

.disable-frac-select.Dark {
  // opacity: 1;

  .ant-select-selector {
    background: $dark-2 !important;
  }

  .ant-select-disabled {
    background: $dark-2 !important;
  }
}

.custom-select-search {
  position: relative;

  .search-icon {
    position: absolute;
    top: 50%;
    left: 13%;
    transform: translate(-50%, -50%);
    svg {
      width: 20px !important;
    }
  }

  .frac-select {
    .ant-select-selector {
      padding-left: 35px !important;
      max-width: 150px;

      .ant-select-selection-search {
        .ant-select-selection-search-input {
          padding-left: 25px !important;
        }
      }
    }
  }
}

.frac-select-with-search {
  .search-icon {
    color: $dark-mode-grey-2;
  }
}

.frac-select-with-search-Dark {
  .search-icon {
    color: $dark-mode-grey-1;
    z-index: 10;
  }
}

.frac-select-popup-common-Dark {
  background: $dark-1;
  border: 1px solid $dark-mode-grey-1;

  .ant-select-item-option-active,
  .ant-select-item-option-selected {
    background: $dark-2;
  }

  .ant-select-item-option-content {
    color: $text-color-white;
  }

  .ant-select-item-empty {
    color: $text-color-white;
  }
}
