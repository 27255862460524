@import 'src/assets/scss/variable.scss';

.ant-modal-content {
  background: $color-pure-white;
  border: 1px solid $color-grey-5;
  border-radius: 8px;
  color: $text-color-white;
  padding: 20px 30px;

  .ant-modal-header {
    padding: 0;
    border: none;

    .ant-modal-title {
      font-family: 'Poppins-Bold';
      font-weight: 700;
      color: $color-pure-black;
      font-size: 24px;
      line-height: 36px;
    }
  }

  .ant-modal-body {
    padding: 0;
    margin-top: 20px;
  }
}

.ant-modal-close > .ant-modal-close-x {
  width: 90px;
  line-height: 90px;
}

.close-modal-icon {
  color: $color-pure-black;
}

.close-modal-icon.Dark {
  color: $text-color-white;
}

.frac-modal-wrapper-Dark {
  .ant-modal-content {
    background: $dark-1;
    border: 1px solid $dark-mode-grey-1;

    .ant-modal-header {
      background: transparent;

      .ant-modal-title {
        color: $text-color-white;
      }
    }
  }
}
