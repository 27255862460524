@import 'src/assets/scss/variable.scss';

.profile-detail {
  background: $color-pure-white;
  color: $color12;
  width: 280px;
  border-radius: 8px;
  font-family: 'Poppins-Medium';
  font-weight: 500;
  border: 1px solid $color-grey-5;

  &__item-wrapper {
    padding: 4px;

    .item-icon {
      margin-right: 16px;
    }
  }

  .profile-detail-icon {
    color: $color12;
  }

  &__item {
    display: flex;
    align-items: center;
    font-style: normal;
    font-size: 14px;
    height: 44px;
    padding: 12px 16px;
    color: $color12;

    &:hover {
      color: $color12;
      background-color: $color-grey-3;
      border-radius: 4px;
    }

    .profile-address {
      font-weight: 600;
    }

    .icon-group {
      display: flex;
      align-items: center;
      margin-top: -1px;

      img:nth-child(1) {
        margin-right: 10px;
      }
    }

    .text-copied {
      font-size: 12px;
      color: $color-grey-2;
      margin-right: 6px;
    }

    .icon-logout {
      margin-right: 10px;
    }

    .dark-light-mode {
      display: flex;
      align-items: center;
      margin-top: -2px;

      span {
        margin-right: 5px;
      }
    }

    .language-select {
      display: flex;
      align-items: center;

      &__choice {
        margin-left: 10px;
        color: $color-grey-2;

        &:hover {
          text-decoration: underline;
        }
      }

      &__choice__active {
        color: $color12;
        text-decoration: underline;
      }
    }
  }

  .line {
    height: 1px;
    margin: 2px 0;
    background-color: $color-grey-5;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .justify-flex-start {
    justify-content: flex-start;
  }

  .justify-between {
    justify-content: space-between;
  }

  .pb-0 {
    padding-bottom: 0;
  }
}

.language-content {
  @extend .profile-detail;

  width: 260px;

  .check-icon {
    width: 8px;
    height: 8px;
  }

  .visibility-hidden {
    visibility: hidden;
  }
}

.profile-detail.Dark {
  background: $dark-1;
  color: $text-color-white !important;
  border: 1px solid $dark-mode-grey-1;

  .profile-detail-icon {
    color: $text-color-white;
  }

  .profile-detail__item {
    color: $text-color-white;
    &:hover {
      color: $text-color-white;
      background-color: $dark-2;
    }
  }

  .line {
    background-color: $dark-mode-grey-1;
  }
}
