@import 'src/assets/scss/variable.scss';

.modal-disconnect {
  .modal-disconnect__content {
    span {
      display: block;
      margin: 0;
      font-family: 'Poppins-Regular';
      font-style: normal;
      font-size: 16px;
      line-height: 18px;
      color: black;
    }

    .modal-disconnect__content__title {
      margin-bottom: 24px;

      span {
        font-family: 'Poppins-Bold';
        font-weight: 700;
        font-style: normal;
        font-size: 24px;
        line-height: 29px;
        color: black;
      }
    }
  }

  .button-confirm {
    width: 100%;
    margin-top: 35px;
    background: $color13;
    border-radius: 5px;
    border: none;
    outline: none;
    font-family: 'Poppins-SemiBold';
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: $text-color-white;
    padding: 8px;
    vertical-align: middle;
    height: fit-content;

    &:hover {
      background: $color13 !important;
      color: $text-color-white !important;
      opacity: 0.9 !important;
    }
  }
}

.disconnect-wallet-modal-Dark {
  .modal-disconnect__content {
    span {
      color: $text-color-white;
    }

    .modal-disconnect__content__title {
      span {
        color: $text-color-white;
      }
    }
  }

  .button-confirm {
    background: $color13;
    color: $text-color-white;

    &:hover {
      background: $color13 !important;
      color: $text-color-white !important;
      opacity: 0.9 !important;
    }
  }
}
