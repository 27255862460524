@import 'src/assets/scss/variable.scss';

.wallet-detail {
  background: $color-pure-white;
  color: $color12;
  width: 280px;
  border-radius: 8px;
  font-family: 'Poppins-Medium';
  font-weight: 500;
  border: 1px solid $color-grey-5;

  .item-wrapper {
    padding: 4px;
  }

  &__item {
    display: flex;
    align-items: center;
    font-style: normal;
    font-size: 14px;
    height: 44px;
    padding: 12px 16px;

    &:hover {
      background-color: $color-grey-3;
      border-radius: 4px;
    }

    .wallet-address {
      font-weight: 600;
    }

    .icon-group {
      display: flex;
      align-items: center;
      margin-top: -1px;

      img:nth-child(1) {
        margin-right: 10px;
      }
    }

    .text-copied {
      font-size: 12px;
      color: $color-grey-2;
      margin-right: 6px;
    }

    .tab-icon {
      margin-right: 16px;
    }
  }

  .active {
    background-color: $color-grey-3;
    border-radius: 4px;
  }

  .line {
    height: 1px;
    margin: 2px 0;
    background-color: $color-grey-5;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .justify-flex-start {
    justify-content: flex-start;
  }

  .justify-between {
    justify-content: space-between;
  }

  .pb-0 {
    padding-bottom: 0;
  }

  .wallet-detail-icon {
    color: $color12;
  }

  .mr-10 {
    margin-right: 10px;
  }
}

.wallet-detail.Dark {
  background: $dark-1;
  border: 1px solid $dark-mode-grey-1;
  color: $text-color-white;

  .item-wrapper {
    .wallet-detail__item {
      &:hover {
        background: $dark-2;
      }

      .text-copied {
        color: $text-color-white;
      }
    }
  }

  .active {
    background-color: $dark-2;
  }

  .line {
    background-color: $dark-mode-grey-1;
  }

  .wallet-detail-icon {
    color: $text-color-white;
  }
}

.color {
  background-color: red !important;
}
.ant-tooltip-arrow {
  display: none;
}
.tooltip .ant-tooltip-content {
  .ant-tooltip-inner {
    border-radius: 8px;
    padding: 13px 24px !important;
  }
}

.tooltip.Dark .ant-tooltip-content {
  .ant-tooltip-inner {
    background-color: $color-grey-11;
  }
}
