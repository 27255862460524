@import 'src/assets/scss/variable.scss';
@import 'src/assets/scss/responsive.scss';

.sub-menu-container {
  display: flex;
  align-items: center;

  .menu-item {
    margin-right: 30px;
    cursor: pointer;

    img {
      filter: $filter-color-gray-icon;
    }

    &__label {
      font-size: 0.875rem;
      font-family: 'Poppins-Bold';
      color: $color-grey-2;
    }

    &:hover {
      .menu-item__label {
        color: $color13 !important;
      }

      img {
        filter: $filter-color-green-icon;
      }
    }

    &--active {
      .menu-item__label {
        color: $color13 !important;
      }

      img {
        filter: $filter-color-green-icon;
      }
    }
  }

  .menu-more {
    cursor: pointer;

    img {
      filter: $filter-color-gray-icon;
    }

    &:hover {
      img {
        filter: $filter-color-green-icon;
      }
    }
  }
}

.dropdown {
  .ant-dropdown-menu {
    width: 180px;

    .ant-dropdown-menu-item {
      font-family: 'Poppins-Medium';
      color: $color12;
    }
  }
}

.dropdown.Dark {
  .ant-dropdown-menu {
    background: $dark-1;
    border-color: $dark-mode-grey-1;

    .ant-dropdown-menu-item {
      color: $dark-mode-grey-4;

      &:hover {
        background: $dark-2;
      }
    }
  }
}

// .container .site-layout-header {
//   .sidebar-wrapper {
//     .ant-menu-overflow {
//       background: transparent;
//     }
//   }

//   .sub-menu-container {
//     display: flex;
//   }

//   .sub-menu-container .ant-menu {
//     // @include tablet {
//     //   width: 300px;
//     // }

//     // @include tablet2 {
//     //   width: 200px;
//     // }

//     // @include desktopFrac {
//     //   width: 400px;
//     // }

//     // @include mobile {
//     //   display: none;
//     // }

//     @include mobileFrac {
//       display: none;
//     }

//     border: none;

//     .dropdown-icon {
//       margin-left: 8px;
//     }

//     .ant-menu-item-icon {
//       margin-left: 14px;
//     }

//     .ant-menu-title-content {
//       margin: 0 14px;
//     }

//     .ant-menu-item,
//     .ant-menu-submenu {
//       padding: 0 5px;

//       span,
//       a {
//         font-family: 'Poppins-Bold';
//         font-weight: 700;
//         font-style: normal;
//         font-size: 14px;
//         color: $color-grey-2;
//       }

//       &:hover {
//         color: $color13;
//         span,
//         a {
//           color: $color13;
//         }
//       }
//     }

//     .ant-menu-item-active,
//     .ant-menu-item-selected,
//     .ant-menu-submenu-active,
//     .ant-menu-submenu-selected {
//       img {
//         filter: invert(57%) sepia(82%) saturate(6579%) hue-rotate(157deg) brightness(94%)
//           contrast(101%);
//       }

//       span,
//       a {
//         font-family: 'Poppins-Bold';
//         font-weight: 700;
//         font-style: normal;
//         font-size: 14px;
//         color: $color13;
//       }

//       &::after {
//         right: 5px;
//         left: 5px;
//         border-bottom: 4px solid transparent;
//       }
//     }

//     .ant-menu-item:hover,
//     .ant-menu-submenu:hover,
//     .ant-menu-item-active:hover,
//     .ant-menu-item-selected:hover,
//     .ant-menu-submenu-active:hover,
//     .ant-menu-submenu-selected:hover {
//       background: transparent;

//       span,
//       a {
//         font-family: 'Poppins-Bold';
//         font-weight: 700;
//         font-style: normal;
//         font-size: 14px;
//         color: $color13;
//       }

//       img {
//         filter: invert(57%) sepia(82%) saturate(6579%) hue-rotate(157deg) brightness(94%)
//           contrast(101%);
//       }

//       &::after {
//         right: 5px;
//         left: 5px;
//         border-bottom: 4px solid transparent;
//         // border-bottom: 4px solid $color13;
//       }
//     }
//   }

//   .sub-menu-container .menu-vi_VN {
//     @include desktop {
//       width: 360px;
//     }
//   }
// }

// .ant-menu-submenu-popup {
//   top: 50px !important;
//   padding-top: 21px;

//   .ant-menu-sub {
//     min-width: 232px;
//     background: $text-color-white;
//     border: 1px solid $color-grey-5;
//     border-radius: 8px;
//     padding: 0 4px;

//     .ant-menu-item {
//       padding: 0;
//       display: flex;
//       align-items: center;

//       img {
//         width: 24px;
//         height: 24px;
//         margin-left: 5px;
//       }

//       &:hover {
//         background: $color-grey-3;
//         border-radius: 4px;
//         width: 100%;
//       }
//     }

//     .ant-menu-item .ant-menu-title-content {
//       font-family: 'Poppins-Medium';
//       font-weight: 500;
//       font-style: normal;
//       font-size: 14px;
//       display: flex;
//       align-items: center;
//       color: $color12;
//       padding: 0px 16px;
//       width: 100%;

//       a {
//         color: $color12;
//       }

//       a:hover {
//         color: $color12;
//       }

//       a:focus {
//         color: $color12;
//       }
//     }

//     .ant-menu-item-selected {
//       background: $color-grey-3;
//       border-radius: 4px;
//       width: 100%;
//     }
//   }
// }

// .ant-menu-submenu-popup.ant-menu-dark .ant-menu {
//   background: $dark-1;
//   border: 1px solid $dark-mode-grey-1;

//   .ant-menu-item {
//     &:hover {
//       background: $dark-2;
//     }
//   }

//   .ant-menu-item .ant-menu-title-content {
//     color: $text-color-white;

//     a {
//       color: $text-color-white;
//     }

//     a:hover {
//       color: $text-color-white;
//     }

//     a:focus {
//       color: $text-color-white;
//     }
//   }
// }
