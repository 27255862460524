$body-color: #454545;
@import 'src/assets/scss/variable.scss';

.toast-body {
  padding: 5px 0;
  margin-right: 10px;

  &__top {
    color: $color12 !important;
    font-size: 16px !important;
    font-family: 'Poppins-Bold';
    font-weight: 700;
    line-height: 24px;
  }

  &__bottom {
    color: $body-color !important;
    font-size: 12px !important;
    font-family: 'Poppins-Regular';
    line-height: 18px;
  }
  a {
    color: #000;
    text-decoration: underline;
  }
  img {
    margin: 0 13px 0 21px;
  }
}

.toast-close-icon {
  cursor: pointer;
}
