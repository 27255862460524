@import 'src/assets/scss/variable.scss';
@import 'src/assets/scss/responsive.scss';

#layout {
  font-family: 'Poppins-Regular';
}

#layout .container {
  min-height: 100vh;
  height: fit-content;
}

#layout .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.header {
  padding: 34px 0 4px 0;
  background: $color-soft-blue;

  .sidebar-icon {
    width: 20px;
    height: 14px;
    cursor: pointer;
  }

  .frac-icon {
    width: 100px;
    height: 38px;
    margin-left: 18px;
  }
}

#layout .trigger:hover {
  color: $color-6th-blue;
}

#layout .site-layout {
  background: $color-soft-blue;
}

.site-layout .site-layout-background {
  // background: $color-soft-blue;
  background: $color-grey-3;
  min-height: 280px;
  padding: 0 50px;
  padding-bottom: 100px;
  overflow-x: hidden;
  @include tabletFrac {
    padding: 0px;
  }

  .display-none {
    display: none;
  }
}

.site-layout.Dark .site-layout-background {
  background: $dark-2;
}
