@import 'src/assets/scss/variable.scss';

.contained-default {
  border: none;
  color: $color-pure-white;
  border-radius: 5px;
  background: $color13;
  font-size: 12px;
  font-family: 'Poppins-SemiBold';
  font-weight: 600;
  &:hover {
    color: $color-pure-white;
    background: $color13;
  }
}
