@import 'src/assets/scss/responsive.scss';
@import 'src/assets/scss/variable.scss';

.container .site-layout-header {
  display: flex;
  justify-content: space-between;
  padding: 0 50px 0 50px;
  z-index: 1;
  box-shadow: 0px 2px 8px rgba(27, 25, 56, 0.05);
  background: $text-color-white;

  @include tabletFrac {
    align-items: center;
    padding: 20px 18px;
    box-shadow: none;
  }

  .sidebar-wrapper {
    display: flex;
    align-items: center;
  }

  .menu-icon-mobile {
    display: none;

    @include tabletFrac {
      display: inline-block;
      margin-top: 10px;
    }
  }

  .logo-section {
    @include mobile {
      img:nth-child(2) {
        margin-left: 20px;
      }
    }

    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 32px;
  }

  .mobile-none {
    @include tabletFrac {
      display: none;
    }
  }

  .info-section {
    @include tabletFrac {
      .setting {
        display: none;
      }
    }

    display: flex;
    align-items: center;
    justify-content: space-around;

    .text-box {
      display: flex;
      align-items: center;
      height: 32px;
      font-style: normal;
      letter-spacing: 0.875px;
      color: $text-color-white;
      border-radius: 8px;
      padding: 9px;

      img {
        margin-right: 8px;
        width: 25px;
      }
    }

    .network {
      @include tabletFrac {
        display: none;
      }

      margin-right: 20px;
      background-color: $color-grey-7;
      font-size: 14px;
      font-family: 'Poppins-Bold';
      font-weight: 700;
      line-height: 14px;
      color: $color-grey-4;
      border: 1px solid $color-grey-5;
      padding: 10px 16px;
      height: 44px;
      background: none;
    }

    .highlight-icon {
      cursor: pointer;
      margin-right: 18px;
      color: $color-grey-2;

      &:hover {
        color: $color13;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .active-icon {
      color: $color13;
    }

    .badge {
      @extend .highlight-icon;

      .ant-badge-count {
        top: 6px;
        background-color: $color11;
        padding: 5px 1px;
        font-family: 'Poppins-SemiBold';
        font-weight: 600;
        font-style: normal;
        color: $text-color-white;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        font-size: 10px;
      }
    }

    .ant-dropdown-open {
      color: $color13;
    }

    // when has notifications, count > 0
    .mr-28 {
      margin-right: 28px;
    }

    .count-small {
      .ant-badge-count {
        right: -1px;
        font-size: 12px;
        background-color: $color13;
      }
    }

    .count-medium {
      .ant-badge-count {
        right: -1px;
        font-size: 11px;
        background-color: $color13;
      }
    }

    .count-large {
      .ant-badge-count {
        right: -1px;
        background-color: $color13;
      }
    }

    .setting-icon-no-auth {
      cursor: pointer;
    }

    .wallet-address {
      background-color: $color13;
      margin-right: 15px;
      font-family: 'Poppins-Bold';
      font-weight: 700;
      font-size: 14px;
      border: none;
      color: $color-pure-white !important;
      border-radius: 8px;
      padding: 12px 16px;
      height: 44px;

      @include tabletFrac {
        display: none;
      }

      // .float-message-wrapper {
      //   @include mobile {
      //     font-size: 9px;
      //   }

      //   border: 1px solid $color-grey-5;
      //   position: absolute;
      //   top: 130%;
      //   z-index: 1;
      //   color: $color-pure-black;
      //   right: 0;
      //   padding: 14px;
      //   background: $color1;
      //   border-radius: 8px;
      //   font-size: 14px;
      //   font-family: 'Poppins-SemiBold';
      //   font-weight: 600;

      //   .triangle-up {
      //     width: 0;
      //     height: 0;
      //     border-left: 7px solid transparent;
      //     border-right: 7px solid transparent;
      //     border-bottom: 10px solid $background-float-message;
      //     position: absolute;
      //     bottom: 100%;
      //     right: 3%;
      //   }
      // }
    }

    .tier-wrapper {
      span {
        font-family: 'Poppins-Bold';
        font-size: 14px;
        color: $color-orange-1;
        cursor: pointer;
      }
    }
  }
}

.notification-container {
  background: $color-pure-white;
  border-radius: 8px;
  border: 1px solid $color-grey-5;

  .line {
    height: 1px;
    background-color: $color-grey-5;
  }

  .secondary-line {
    height: 1px;
    background-color: $color-grey-9;
    margin: 8px 16px;
  }

  .notification__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 26px;
    padding-bottom: 12px;
    min-width: 456px;

    .count {
      font-family: 'Poppins-Bold';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: $color12;
    }

    .mark-all {
      font-family: 'Poppins-Regular';
      font-size: 14px;
      line-height: 21px;
      color: $color-grey-2;
      cursor: pointer;
    }
  }

  .notification__content {
    padding: 8px;

    .notification__item {
      width: 440px;
      display: flex;
      align-items: center;
      padding: 8px 16px;
      cursor: pointer;

      &:hover {
        background: $color-grey-9;
        border-radius: 4px;
      }

      .dot-icon {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 8px;
      }

      .inactive {
        background: $color-grey-5;
        visibility: hidden;
      }

      .active {
        background: $color13;
      }

      .notification-icon {
        color: $color12;
        margin-right: 16px;
      }

      .notification-item__content {
        font-family: 'Poppins-Regular';
        font-style: normal;
        line-height: 150%;
        display: flex;
        flex-direction: column;

        .title {
          font-size: 14px;
          color: $color-pure-black;
        }

        .content {
          font-size: 12px;
          color: $color-grey-2;

          .event_name_link {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            color: $color-green-7;
            cursor: pointer;
          }
        }

        .time {
          font-size: 12px;
          color: $color-grey-2;
        }
      }
    }
  }

  .view-all-notification {
    color: $color13;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    margin-bottom: 24px;
    cursor: pointer;
    a {
      color: $color13;
    }
    &:hover {
      opacity: 0.8;
    }
  }
}

.notification-container.Dark {
  background: $dark-1;
  border: 1px solid $dark-mode-grey-1;

  .line {
    background-color: $dark-mode-grey-1;
  }

  .secondary-line {
    background-color: $dark-2;
  }

  .notification__header {
    .count {
      color: $text-color-white;
    }

    .mark-all {
      color: $dark-mode-grey-2;
    }
  }

  .notification__content {
    .notification__item {
      &:hover {
        background: $dark-2;
      }

      .inactive {
        background: $color-grey-5;
      }

      .active {
        background: $color13;
      }

      .notification-icon {
        color: $text-color-white;
      }

      .notification-item__content {
        .title {
          color: $text-color-white;
        }

        .content {
          color: $dark-mode-grey-2;
        }

        .time {
          color: $dark-mode-grey-2;
        }
      }
    }
  }
}

.ant-dropdown {
  .ant-dropdown-menu {
    border: 1px solid $color-grey-5;
    border-radius: 8px;
    padding: 4px;

    .ant-dropdown-menu-item {
      border-radius: 4px;
      padding: 10px 16px;

      &:hover {
        background: $color-grey-3;
      }
    }

    .ant-dropdown-menu-item .ant-dropdown-menu-title-content {
      .notification-item {
        display: flex;
        width: 380px;

        .dot-icon {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin: 7px 7px 0 0;
        }

        .inactive {
          background: $color-grey-5;
          visibility: hidden;
        }

        .active {
          background: $color13;
        }

        .notification-item__content {
          font-family: 'Poppins-Regular';
          font-style: normal;
          line-height: 150%;
          display: flex;
          flex-direction: column;

          .title {
            font-size: 14px;
            color: $color-pure-black;
          }

          .content {
            font-size: 12px;
            color: $color-grey-2;
          }

          .time {
            font-size: 12px;
            color: $color-grey-2;
          }
        }
      }
    }
  }
}

.warning-network {
  background: $color11 !important;
  color: $color-pure-white !important;
}

.site-layout-header {
  .logo-section {
    img {
      height: 46px;
    }
  }
}

.site-layout-header.Dark {
  background-color: $dark-1;

  .info-section {
    .network {
      border: 1px solid $dark-mode-grey-1 !important;
    }
  }
  .logo-section {
    img {
      height: 46px;
    }
  }
}

.site-layout-header.Light {
  background-color: $text-color-white;
  .logo-section {
    img {
      height: 46px;
    }
  }
}

.dropdown-wrapper {
  top: 50px !important;
  padding-top: 22px;
}

.mobile-menu-dropdown-wrapper {
  width: 100%;
  top: 64px !important;
}

.menu-language-theme {
  display: flex;
  align-items: center;
  .change-language {
    margin-right: 15px;
    .frac-select-language .ant-select-selector {
      width: 100%;
    }
  }
  .change-theme {
    margin-right: 15px;
    cursor: pointer;
  }
}

.option-text-bold.ant-select-item-option-active.ant-select-item-option-selected {
  font-weight: 600;
}

.content-noti-markdown {
  margin: 4px 0 8px 0;
  color: var(--color_text_desc);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  word-break: break-all;
}

.two-line {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
