@import 'src/assets/scss/variable.scss';
@import 'src/assets/scss/responsive.scss';

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;

  .image {
    margin-top: 20px;
  }

  .description {
    text-align: center;
    margin-top: 40px;
    color: $color12;
    font-size: 16px;

    &__link {
      color: $color13;
      cursor: pointer;
    }
  }
}

.wrap.Dark {
  .description {
    color: $dark-mode-grey-4;
  }
}
